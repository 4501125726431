import request from '../utils/request'

export default {
  async gets (data) {   // 获取列表
    return await request('/v1/order/gets', data, 'POST')
  },
  async getsUserAmount (data) {   // 获取资金明细列表
    return await request('/v1/user_amount/gets', data, 'POST')
  },
  async create (data) {  // 创建订单
    return await request('/v1/order/create', data, 'POST')
  }
}

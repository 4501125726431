<template>
  <div>
    <el-card>
      <el-form>
        <el-form-item :label="$t('pay.amount')" label-width="120px" v-if="source === '1'">
          <el-input v-model="amount" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pay.duration')" label-width="120px" v-if="source === '2'">
          <el-radio :label="1" v-model="time">{{ $t('pay.one') }}</el-radio>
          <el-radio :label="2" v-model="time">{{ $t('pay.two') }}</el-radio>
          <el-radio :label="3" v-model="time">{{ $t('pay.three') }}</el-radio>
        </el-form-item>
        <el-form-item :label="$t('pay.method')" label-width="120px">
          <el-radio :label="1" v-model="pay_type">{{ $t('pay.wechat') }}</el-radio>
          <el-radio :label="2" v-model="pay_type">{{ $t('pay.alipay') }}</el-radio>
        </el-form-item>
        <el-divider/>
        <el-button style="margin-left: 12%" @click="handleBack" type="primary">{{ $t('pay.back') }}</el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import order from '../model/order'

export default {
  name: 'pay',
  data() {
    return {
      amount: 1,
      time: 1,
      pay_type: 1,
      source: '0',
      code: {}
    }
  },
  mounted() {
    this.source = this.$route.query.source
    this.loadData()
  },
  methods: {
    async loadData() {
      const ret = await order.create({
        amount: this.amount,
        time: this.time,
        pay_type: this.pay_type,
        source: this.source
      })
      if (ret.code === 200) {
        this.code = ret
      } else {
        this.$message.error(ret.message)
      }
    },
    handleBack() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>
